
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    emoji: String,
    goTo: String,
    translationKey: {
      type: String,
      required: true,
    },
  },
  methods: {
    redirect() {
      if(this.goTo) {
        window.location.href = this.goTo;
      }
    }
  }
});
