import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-10 m-auto text-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "vp-font-medium font-bold" }
const _hoisted_5 = { class: "vp-font-medium vp-variant" }
const _hoisted_6 = { class: "col-10 m-auto text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.emoji)
        ? (_openBlock(), _createElementBlock("figure", _hoisted_3, _toDisplayString(_ctx.emoji), 1))
        : _createCommentVNode("", true),
      _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t(`${_ctx.translationKey}.heading`)), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t(`${_ctx.translationKey}.subheading`)), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.goTo)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "w-full vp-button font-bold",
            label: _ctx.$t(`cta.${_ctx.translationKey}`),
            type: "submit",
            onClick: _ctx.redirect
          }, null, 8, ["label", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}